<template>
    <div class="body">
        <div v-for="(item, index) in data.items" :key="index">
            <a @click="toUrl(item.router)">
                <div class="icon">
                    <img :src="item.icon" />
                </div>
                <div class="content">
                    <div>
                        <div class="cover">
                            <img v-if="item.cover" :src="item.cover" />
                        </div>
                        <div class="clear"></div>
                    </div>
                    <p>
                        {{ item.description }}
                    </p>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    created() {
        console.log(this.data);
    },
    methods: {
        toUrl(url) {
            if (url.includes('http')) {
                window.open(url);
            } else {
                this.$router.push(url);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.clear {
    clear: both;
}

.body {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 10px;
}

.body a {
    cursor: pointer;
    color: #2b292f;
    text-decoration: none;
    outline: none;
}

.icon {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    height: 150px;
    background: #3c7bbf;
    display: flex;
    justify-content: center;
    align-items: Center;
}

.icon img {
    height: 60%;
}

.content {
    background: #f4f7f9;
    padding-bottom: 40px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.content img {
    width: 100%;
}

.content p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    font-size: 16px;
    line-height: 36px;
    color: rgba(0, 0, 0, 0.6);
    margin: 0 9%;
    margin-top: 35px;
}

.cover {
    padding-top: 30px;
    margin: 0 auto;
    text-align: center;
    width: 90%;
}

.cover img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 700px) {
    .body {
        padding: 0 20px;
    }

    .icon {
        height: 20vw;
    }

    .content {
        background: #f4f7f9;
        padding-bottom: 40px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .content img {
        width: 100%;
    }

    .content p {
        -webkit-line-clamp: 6;
        font-size: 22px;
    }

    .cover {
        margin-left: 5%;
    }
}
</style>
